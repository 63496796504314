import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'

const BlockText = ({ blocks, classOverride }) => {
  const serializers = {
    types: {
      block (props) {
        switch (props.node.style) {
          default:
            return <div className={ classOverride ? classOverride : ''}>{props.children}</div>
        }
      }
    }
  }

  return( <BaseBlockContent blocks={blocks} serializers={serializers} /> );
}

export default BlockText
